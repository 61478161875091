body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.container{
    width: auto;
    padding-right: 5px
}
.img-responsive {
    height: auto;
    width: auto;
    max-height: 75px;
    max-width: 200px;
}
.btn-table {
    padding: 0px;
    color: black;
    border: 0px;
}
.object-description {
    margin-bottom: 0
}
.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
.btn-sso {
    margin: 15px 0;
}
.img-responsive-large {
    height: auto;
    width: auto;
    max-height: 75px;
    max-width: 400px;
    margin-top: 30px;
}
.NotFound {
  padding-top: 100px;
  text-align: center;
}
.img-responsive-large {
    height: auto;
    width: auto;
    max-height: 75px;
    max-width: 400px;
    margin-top: 30px;
}
